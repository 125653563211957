import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createLogger } from '@remento/logger';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMPhoneInput } from '@/components/RMPhoneInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { createAccountForm } from '@/modules/account/account.form';
import { AccountSetupAvatarContainer } from '@/modules/account/containers/AccountSetupAvatar.container';
import { InputContainer, InputController } from '@/modules/form/container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { captureException } from '@/utils/captureException';
import { isPhoneNumberEmpty } from '@/utils/phone-number';

import {
  Checkbox,
  CheckboxWrapper,
  Divider,
  Link,
  LogoutLink,
  Page,
  PageContent,
  PageFooter,
} from './AccountNamePage.styles.js';

const logger = createLogger('account-name-page');

function InternalAccountNamePage() {
  const { userService, redirectService, browserAnalytics } = useServices();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const form = useMemo(
    () =>
      createAccountForm({
        firstName: personQuery.data?.name?.first,
        lastName: personQuery.data?.name?.last,
        phone: user?.communicationChannels.phone ?? null,
        marketingConsent: true,
      }),
    [personQuery.data?.name?.first, personQuery.data?.name?.last, user?.communicationChannels.phone],
  );
  const isFormValid = useIsFormValid(form);

  const handleSubmit = useCallback(async () => {
    if (user == null) {
      // This will never happen
      logger.error('USER_NOT_LOADED');
      return;
    }

    await submitForm(form, async ({ firstName, lastName, marketingConsent, phone }) => {
      try {
        await userService.updateUserProfile({
          name: { first: firstName, last: lastName },
          phone: isPhoneNumberEmpty(phone) ? null : phone,
          termsAccepted: true,
        });

        await browserAnalytics
          .identify({ type: 'id', value: user.ruid }, 'book-account-setup', {
            firstName,
            lastName,
            unsubscribed: !marketingConsent,
          })
          .catch((error) => logger.error('IDENTIFY_FAILED', () => ({ error })));

        const redirect = await redirectService.consumeRedirect('account-setup');
        navigate(redirect ?? '/');
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [browserAnalytics, form, navigate, redirectService, user, userService]);

  return (
    <Page>
      <PageContent>
        <RMText type="serif" size="l" color="on-surface-primary">
          Welcome to Remento!
        </RMText>
        <RMSpacer direction="column" spacing="md" />
        <RMText type="sans" size="s" color="on-surface-primary">
          Let’s finish setting up your account.
        </RMText>

        <RMSpacer direction="column" spacing="2xl" />
        <AccountSetupAvatarContainer form={form} />
        <RMSpacer direction="column" spacing="2xl" />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputContainer form={form} path="firstName">
            {(props) => <RMInput id="first-name" label="First name" placeholder="John" {...props} />}
          </InputContainer>
          <RMSpacer direction="column" spacing="2xl" />
          <InputContainer form={form} path="lastName">
            {(props) => <RMInput id="last-name" label="Last name" placeholder="Doe" {...props} />}
          </InputContainer>
          <RMSpacer direction="column" spacing="2xl" />
          <InputController form={form} path="phone">
            {(props) => <RMPhoneInput label="Phone number (optional)" {...props} />}
          </InputController>
          <RMSpacer direction="column" spacing="sm" />
          <RMText type="sans" size="xs" color="on-surface-tertiary">
            Remento sends helpful reminders via text (currently US numbers only). Text STOP or disable in settings to
            unsubscribe. Msg & data rates may apply..
          </RMText>

          <RMSpacer direction="column" spacing="md" />
          <Divider />
          <RMSpacer direction="column" spacing="md" />

          <InputController form={form} path="marketingConsent">
            {(props) => (
              <CheckboxWrapper>
                <Checkbox
                  square
                  checked={props.value ?? false}
                  onChange={props.onChange as (value: boolean) => void}
                  onBlur={props.onBlur}
                />
                <RMText type="sans" size="s" color="on-surface-primary">
                  I would like to receive helpful tips, webinar invitations, and occasional VIP promotions via email and
                  SMS. Unsubscribe at any time.
                </RMText>
              </CheckboxWrapper>
            )}
          </InputController>
          <RMSpacer direction="column" spacing="md" />
          <RMText type="sans" size="xs" color="on-surface-tertiary">
            Privacy is important to us. By continuing, you acknowledge that you have read and accept Remento’s&nbsp;
            <Link href="https://www.remento.co/policies/terms-of-service" target="_blank">
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link href="https://www.remento.co/policies/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </RMText>
        </form>
      </PageContent>

      <PageFooter>
        <RMButton background="primary" disabled={isFormValid === false} autoLoading fullWidth onClick={handleSubmit}>
          Continue
        </RMButton>

        <RMText type="sans" size="xs" color="on-surface-secondary">
          Not{' '}
          <RMText type="sans" bold size="xs" color="on-surface-secondary">
            {user?.communicationChannels.email}
          </RMText>
          {'? '}
          <LogoutLink onClick={() => signOut()}>Log out.</LogoutLink>.
        </RMText>
      </PageFooter>
    </Page>
  );
}

export function AccountNamePage() {
  return (
    <RementoPage type="default">
      <InternalAccountNamePage />
    </RementoPage>
  );
}
